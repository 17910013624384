import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Container from 'react-bootstrap/Container';
import User from "./User";
import Login from "./Login";
import Word from "./Word";
import CrossGrid from "./Crossword/CrossGrid";

function App() {
  return (
      <Container fluid="fluid" >
          <BrowserRouter>
            <div className="App">
                <Routes>
                    <Route path="/word" element={<Word />} />
                    <Route path="/" element={<Login />} />
                    <Route path="/user" element={<User />} />
                    <Route path="/crossword" element={<CrossGrid/>} />
                    <Route path="/web-crossword" element={<CrossGrid/>} />
                    <Route path="/rus-crossword" element={<CrossGrid/>} />
                </Routes>
            </div>
          </BrowserRouter>
      </Container>
  );
}

export default App;
