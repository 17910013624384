import React, {Fragment} from "react"
import {Button} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { LANGUAGES } from "../constants";
import { Translation } from 'react-i18next';
import i18n from '../i18n';

function withHooks(WrappedComponent) {
    return function(props) {
        const nav = useNavigate();
        return <WrappedComponent nav={nav} {...props}/>
    };
}

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'lang': i18n.language,
            'name': JSON.parse(localStorage.getItem('spr_name')),
            'thema': ''
        };
        //const { t, i18n } = Translation("common");
        //console.log(i18n.language);
    }

    handleClick = () => {
        const navigate = this.props.nav;
        localStorage.setItem('spr_name', JSON.stringify(this.state.name));
        navigate("/word", {state:{lang: i18n.language, name: this.state.name}})
    };

    crossword = (thema) => {
        const navigate = this.props.nav;
        localStorage.setItem('spr_name', JSON.stringify(this.state.name));
        navigate("/"+thema, {state:{lang: i18n.language, name: this.state.name, thema: thema}})
    };

    handleNickChange = (e) => {
        this.setState({name: e.target.value});
    };

    handleLangChange = (e) => {
        this.setState({lang: e.target.value});
        i18n.language = e.target.value;
       // console.log(i18n.language);
    };

    render() {
        return (
            <header className="App-header d-grid gap-2">
                <Fragment>
                    <input
                        className="form-control"
                        id="nick-input"
                        type="text"
                        placeholder="Введите Ник"
                        value={this.state.name}
                        onInput={this.handleNickChange}/>

                    <select className="form-select form-select-lg mb-3"
                            id="lang" value={this.state.lang} onInput={this.handleLangChange}>
                        {LANGUAGES.map(({code, label}) => (
                            <option key={code} value={code}>
                                {label}
                            </option>
                        ))}
                    </select>
                </Fragment>
                <h3 id="haupt_header">
                    Crosswords Themen
                </h3>
                <a
                    type="button"
                    className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                    onClick={() => this.crossword('crossword')}>
                    <Translation>
                        {
                            t => <h1>{t('Drei-Sprache-Crossword')}</h1>
                        }
                    </Translation>
                </a>

                <a
                    href="#"
                    type="button"
                    className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                    onClick={() => this.crossword('web-crossword')}>
                    <Translation>
                        {
                            t => <h1>{t('C-Webentwickler')}</h1>
                        }
                    </Translation>
                </a>

                <a
                    href="#"
                    type="button"
                    className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                    onClick={() => this.crossword('rus-crossword')}>
                    <Translation>
                        {
                            t => <h1>{t('Русский словарь')}</h1>
                        }
                    </Translation>
                </a>

            </header>
        );
    }
}

export default withHooks(Login)
